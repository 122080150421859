<template>
  <div class="about about-content">
    <h1>О Helperia</h1>

    <h2>Что такое Helperia?</h2>
    <p>Это пространство для обмена опытом и знаниями.</p>
    <p>Helperia – это российский интернет-проект, объединяющий тех, кто ищет знания, и тех, кто ими обладает, тех кто хочет заявить о себе и тех, кто хочет каждый день узнавать новое.</p>
    <p>Проект существует, чтобы помогать людям обмениваться опытом и знаниями в интернете, вне зависимости от местонахождения, пола, возраста, расы и вероисповедания.</p>
    <h2>Как все работает?</h2>
    <p>На сайте существует несколько форматов, чтобы вы могли выбрать наиболее удобный.</p>
    <p>На главной странице собрано самое новое и интересное, а поисковая строка поможет вам найти информацию. Кроме прямого запроса, вы можете указать категорию, в которой искать материал.</p>
    <ul>
      <li>В разделе «Дискуссии» вы можете начать интересное обсуждение или присоединиться к уже существующему – обменивайтесь мнениями и аргументами, чтобы добраться до истины или пообщаться с интересными людьми.</li>
      <li>В разделе «Статьи» вы найдете публичные и авторские статьи или можете создать свою. Публичные статьи можно отредактировать или дополнить, а авторские идеально подходят для первичной публикации результатов научных исследований или персонального опыта.</li>
      <li>В разделе «Вопросы» вы можете задать интересующий вас вопрос, а эксперты или опытные люди вам на него ответят.</li>
      <li>Раздел «Публичные события» содержит мини-блоки предстоящих событий, в которых вы можете принять участие и узнать что-то новое. Если вы сами являетесь организатором события, вы можете о нем рассказать на страницах Helperia.</li>
      <li>Вкладка «Каналы» объединяет мини-блоки людей, организаций и сообществ, которые позиционируют себя как профессионалы в определенной области. Публикуя материалы, эксперты каналов зарабатывают рейтинг, благодаря чему они будут находится на более заметных позициях на сайте.</li>
    </ul>

    <h2>Как оказывается помощь в рамках Helperia?</h2>
    <p>Вся информация и помощь в Helperia предоставляется бесплатно. Вы можете задать свой вопрос и получить на него ответ онлайн и без регистрации.</p>

    <h2>Как мне обратиться за помощью?</h2>
    <p>Попробуйте задать запрос в поисковой строке – в результатах поиска вы увидите все материалы, которые уже были созданы пользователями. Возможно, ответ на ваш вопрос уже был дан. Если результаты поиска не дали нужных вам результатов, то создайте свой вопрос – это можно сделать без регистрации.</p>

    <h2>Как я могу помогать пользователям Helperia?</h2>
    <p>Вы можете поделиться своим опытом – написать ответ на вопрос, вступить в дискуссию или создать статью. Много времени это не займет, зато поможет множеству людей!</p>
    <p>Обновляйте и дополняйте публичные статьи – чтобы информация всегда была актуальной. Обсуждайте предложенные ответы и статьи в комментариях – ведь именно в споре рождается истина.</p>
    <p>У любого ответа или статьи есть рейтинг - поставьте ему свою оценку. Так мы сможем поддерживать высокое качество предоставляемой информации.</p>

    <h2>Как я могу помочь Helperia?</h2>
    <p>Создавая знания в рамках нашего проекта, вы уже помогаете Helperia, но если вы хотите большего, то есть и другие возможности:</p>
    <ul>
      <li>Посоветуйте нас своим друзьям. Чем больше нас,тем большими знаниями мы обладаем. </li>
      <li>Создайте свой канал или станьте модератором,чтобы помочь нам с постоянно обновляющимся контентом. </li>
      <li>У вас есть предложения по улучшению проекта? Отправляйте их нам! </li>
      <li>Вы можете отправить взнос на развитие проекта </li>
      <li>Мы уверены, ваша помощь не останется незамеченной!</li>
    </ul>

    <h2>Что еще интересного есть в Helperia?</h2>
    <ul>
      <li>На сайте происходит автоматическое ранжирование ответов, статей и каналов по рейтингу. Рейтинг каналов складывается из многих факторов, в том числе из опыта сотрудничества с ними других пользователей, поэтому вы не ошибетесь при выборе партнера. </li>
      <li>Вы хотите обсудить какую-нибудь тему, но ее нет на нашем сайте? Вы можете начать дискуссию или задать вопрос, и самостоятельно присвоить тему. Если тема привлечет общественный резонанс, то мы ее сделаем категорией  </li>
      <li>Вы можете написать статью и поделиться своим опытом с пользователями. </li>
      <li>Вы можете стать модератором и членом команды Helperia  </li>
      <li>Если вы – профессионал, то просто регистрируйтесь в качестве эксперта зарабатывайте на ваших знаниях и опыте! Вы можете привлекать к себе клиентов, отвечая на их вопросы и создавая интересныеи полезные материалы.</li>
    </ul>
    <p>Вам не нужно создавать свой собственный сайт,чтобы привлечь потребителей. Вам не нужно тратить деньги на раскрутку своего сайта или контекстную рекламу – рейтинг обеспечит вам более высокие позиции, что дает вам бесплатное продвижение среди заинтересованной аудитории.</p>
    <p>Часто Задаваемые Вопросы: <router-link :to="{ name: 'about-faq' }">FAQ</router-link></p>
    <p>Все публикации о <router-link :to="{ name: 'tag-card-list', params: { slug: 'Helperia' } }">Helperia</router-link></p>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style lang="less" scoped>

</style>
