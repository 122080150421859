<template>
  <div class="contacts about-content">
    <h1>Контакты</h1>
    <p>E-mail: <a href="mailto:info@helperia.ru">info@helperia.ru</a></p>
  </div>
</template>

<script>
export default {
  name: 'Contacts',
}
</script>

<style lang="less" scoped>

</style>
