<template>
  <main>
    <div class="about-nav">
      <router-link :to="{ name: 'about-about' }">О Helperia</router-link>
      <router-link :to="{ name: 'about-terms' }">Пользовательское соглашение</router-link>
      <router-link :to="{ name: 'about-privacy' }">Политика конфиденциальности</router-link>
      <router-link :to="{ name: 'about-faq' }">FAQ</router-link>
      <router-link :to="{ name: 'about-contacts' }">Контакты</router-link>
    </div>
    <router-view/>
  </main>
</template>

<script>
export default {
  name: 'About',

  mounted() {
    if(this.$route.name === 'about') {
      this.$router.replace({ name: 'not-found' })
    }
  },

  watch: {
    $route: 'routeUpdated',
  },

  methods: {
    routeUpdated() {
      window.scrollTo({ top: 0 })
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
  }

  .about-nav {
    margin-bottom: 2em;

    display: flex;
    flex-wrap: wrap;

    a {
      border-bottom: 1px dotted @color-text;
      color: @color-text;
      margin: 0 1em;
      font-size: 13pt;
      padding: 0.3em 0;
      box-sizing: border-box;

      @media @mobile-m {
        font-size: 12pt;
        margin: 0 0.5em;
      }

      &.router-link-exact-active {
        background-color: @color-accent;
        color: @color-block-background;
        border: unset;
        margin: 0;
        padding: 0.3em 1em;

        @media @mobile-m {
          padding: 0.3em 0.5em;
        }
      }
    }
  }
</style>

<style lang="less">
  @import (reference) '../shared.less';

  .about-content {
    p, ul, ol {
      line-height: 1.4;
    }

    h1,
    h2,
    h3 {
      font-family: @font-set-header;
    }

    ul {
      padding-left: 1.25em;
    }

    ol {
      padding-left: 2em;

      li {
        &::after {
          content: ';';
        }

        &:last-child::after {
          content: '.';
        }
      }
    }
  }
</style>
