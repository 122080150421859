<template>
  <div class="faq about-content">
    <h1>FAQ</h1>

    <h2>Как найти информацию в Helperia?</h2>
    <p>Введите в поисковую строку свой вопрос, а наша система произведет поиск одновременно по всем разделам сайта. Также Вы можете выбрать конкретный тег, тогда система предоставит Вам список отсортированных по рейтингу статей, ответов на вопросы, экспертов, публичных событий и дискуссий по данному тегу.</p>

    <h2>Зачем нужна регистрация?</h2>
    <p>Регистрация займет у Вас не больше минуты, при этом Вы получите возможность вступить в дискуссию, обзавестись личным кабинетом, написать статью, ответить на вопрос, проголосовать на сайте или рассказать о публичном событии.</p>
    <p>В Helperia существуют следующие варианты регистрации:</p>
    <ul>
      <li>Пользователь</li>
      <li>Эксперт</li>
    </ul>

    <h2>Кто такой Эксперт и как им стать?</h2>
    <p>Эксперт - это пользователь, прошедший соответствующую регистрацию. В отличие от остальных пользователей, эксперт имеет свой мини-блок, который отражается в разделе «Эксперты» и может попадать на главную страницу.</p>
    <p>Чтобы зарегистрироваться в качестве Эксперта, Вам необходимо пройти расширенную регистрацию, которая доступна в Вашем личном кабинете. Согласно правилам нашего сайта, эксперты обязаны постоянно подтверждать свой статус соответствующими действиями, а именно - писать статьи и отвечать на вопросы. В случае длительного отсутствия активности эксперта, мы оставляем за собой право удалить Ваш аккаунт как эксперта, сохранив при этом общую регистрацию на сайте.</p>

    <h2>Насколько достоверная информация находится на Helperia?</h2>
    <p>Мы стремимся к тому, чтобы информация была по-настоящему достоверной, поэтому в своей работе мы ориентируемся на экспертов и профессионалов в своей области. Все материалы на сайте проходят процедуру модерации. Система рейтингов, существующая на нашем сайте, позволяет получать путем общественного голосования наиболее полезные и качественные статьи, при этом Helperia в соответствии с Пользовательским соглашением не несет ответственности за размещенную информацию.</p>

    <h2>Нужно ли платить экспертам за их консультации в рамках Helperia?</h2>
    <p>В рамках проекта Helperia помощь предоставляется абсолютно бесплатно, в том числе и экспертами. Если после получения ответа на заданный вопрос Вы не до конца удовлетворены результатом и желаете узнать подробности у конкретного эксперта, то Вы продолжаете общение уже в личной беседе или переписке. Эти консультации уже могут быть платными, поэтому настоятельно рекомендуем уточнить стоимость услуг прежде, чем ими воспользоваться.</p>

    <h2>Кто такие Администраторы и Модераторы?</h2>
    <p>Модератор – зарегистрированный пользователь, обладающий расширенными правами по сравнению с обычными пользователями. В задачи модератора входит развитие подконтрольной категории, а также обеспечение максимального качества статей и ответов на вопросы. Для выполнения этих задач модератор наделен дополнительными возможностями - удалять и редактировать ответы, блокировать контент и пользователей за нарушение и т. д.</p>
    <p>Администратор – зарегистрированный пользователь, входящий в штат сотрудников Helperia, он координирует работу модераторов, развивает вверенные ему направления деятельности и выполняет административные функций.</p>

    <h2>Как выставляется рейтинг?</h2>
    <p>Рейтинг подсчитывается по специально разработанной формуле, которая включает в себя множество показателей, отображающих действия и активность пользователя/эксперта. При разработке рейтинговой системы мы постарались уменьшить влияние субъективных оценок, голосование возможно за результат работы – статьи, ответы на вопросы, участие в дискуссии. Соответственно, рейтинг эксперта определяют пользователи Helperia путем выставления положительных и отрицательных оценок его материалам. Кроме того, эксперт получает дополнительные положительные баллы за написание и редактирование статей и ответов и дополнительные отрицательные баллы за жалобы на него и его контент.</p>

    <h2>Как форматировать статьи?</h2>
    <p>Форматирование и правильное оформление - важная часть успеха ваших материалов. Подробнее об этом вы можете узнать из <router-link :to="{ name: 'article-content-reader', params: { slug: 'pravila-oformlenija-statej' } }">статьи</router-link>.</p>

    <h2>Кому я могу пожаловаться?</h2>
    <p>Все зависит от причины Вашего недовольства. Для всех видов отношений, которые не требуют внешнего вмешательства, Вы можете воспользоваться рейтингом и личными сообщениями.</p>
    <p>Если же Вы полагаете, что этих мер недостаточно, например, Вы оскорблены другим пользователем, или считаете, что вопрос\статья не соответствует этическим или моральным нормам, или у Вас возник спор с модератором, то обратитесь в Администрацию Helperia.</p>

    <h2>Как я могу помогать людям?</h2>
    <p>Это очень просто!</p>
    <p>Вам всего лишь стоит пройти регистрацию и поделиться своим опытом, написав ответ, статью или вступив в дискуссию. Много времени это не займет, зато поможет пользователям сайта.</p>
    <p>Предлагайте изменения к статьям, чтобы информация всегда была актуальной. Обсуждайте в предложенные ответы и статьи, ведь именно в споре рождается истина.</p>
    <p>Всегда голосуйте и выставляйте рейтинги, только так мы сможем вместе с Вами добиться взвешенности, высокого качества и актуальности представленной на сайте информации и качества услуг экспертов.</p>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
}
</script>

<style lang="less" scoped>

</style>
