<template>
  <div class="privacy about-content">
    <h1>Политика конфиденци&shy;альности</h1>

    <h2>Общие рамки</h2>
    <p>Эта политика относится к данным, которые ООО «ХЕЛПЕРИЯ» (далее «HELPERIA» или «проект») хранит на своих серверах в связи с деятельностью по управлению проектом HELPERIA. HELPERIA не собирает и не сохраняет Ваших персональных данных. При регистрации требуется указать только логин, пароль и адрес электронной почты, в связи с чем HELPERIA вправе осуществлять обработку данных без уведомления уполномоченного органа по защите прав субъектов персональных данных. Любые персональные данные, размещенные пользователем в своём личном кабинете после осуществления регистрации, не обрабатываются HELPERIA, размещаются и раскрываются пользователем на свое личное усмотрение, страх и риск, и HELPERIA не является оператором таких данных в смысле ФЗ РФ №152-ФЗ «О персональных данных» от 27 июля 2006 года.</p>

    <h2>Суть проектов как общедоступных и нацеленных на совместную деятельность</h2>
    <p>Проект HELPERIA создается и развивается совместными усилиями их участников с помощью программного обеспечения. Любой человек с доступом в Интернет (и без всяких иных ограничений) может изменять доступные для свободного редактирования страницы сайта, авторизуясь в качестве зарегистрированного участника. При этом создаваемый редакторами документ сразу становится общедоступным, и также является общедоступной информация обо всех добавленных, удалённых или изменённых словах и фразах. Всё это — публичные действия, и редакторы публично идентифицируются в качестве авторов таких изменений. Все виды вклада в проект и вся публично доступная информация о таких вкладах — лицензируются безотзывно, и третьи лица вправе (с некоторыми ограничениями) свободно копировать, цитировать, повторно использовать и адаптировать к своим целям эту информацию.</p>

    <h2>Деятельность в проекте HELPERIA</h2>
    <p>В целом, эта политика применяется только к собираемой HELPERIA или хранящейся на его серверах персональной информации, не являющейся общедоступной.</p>
    <p>Взаимодействие с проектом, не охваченное этой политикой, включает (но не ограничивается ими) аспекты просмотра и редактирования страниц, подписки на управляемые HELPERIA почтовые списки рассылки и отправки писем в эти списки, а также взаимодействия с добровольцами HELPERIA через систему обработки заявок. Эти взаимодействия могут раскрывать IP-адреса участников, и, возможно, другую личную информацию для неограниченно широкой публики, либо для конкретных групп добровольцев, действующих независимо от HELPERIA.</p>
    <p>Участники также могут взаимодействовать друг с другом вне сайта HELPERIA, через электронную почту, IRC или другие чаты, через независимые сайты, и прежде чем использовать эти методы коммуникации, следует самостоятельно оценить риск, а также свои личные потребности в сохранении персональной информации.</p>

    <h2>Учётные записи пользователей и авторство</h2>
    <p>HELPERIA требует от редакторов регистрироваться в проекте. Авторизованные участники идентифицируются по выбранному ими имени учётной записи. При создании учётной записи участники выбирают пароль, являющийся конфиденциальным и используемый для проверки целостности своей учётной записи. За исключением случаев, когда это может быть необходимо по требованиям законодательства, никто не должен разглашать или сознательно демонстрировать свои пароли к учётной записи и/или куки, сформированные для идентификации участника. После создания, учетные записи участников не удаляются. Существует лишь возможность изменения имени учётной записи, зависящая от политики проекта. HELPERIA не может гарантировать, что имя учётной записи будет изменено по требованию.</p>

    <h2>Подробная информация о сохранении данных</h2>
    <h3>IP-адреса и другая техническая информация</h3>
    <p>Когда посетитель загружает или читает страницу, или отсылает электронное письмо на сервер HELPERIA, то о нём собирается не больше информации, чем в протоколах работы среднестатистического веб-сайта. HELPERIA может хранить полные протоколы таких операций, но они не будут опубликованы или использованы для отслеживания добросовестных пользователей.</p>
    <p>Когда страница редактируется авторизованным участником, сервер в течение ограниченного периода времени сохраняет связанную с IP-адресом конфиденциальную информацию. По истечении установленного срока эта информация автоматически удаляется. Авторизация и работа под зарегистрированной учётной записью гораздо лучше способствует сохранению конфиденциальности.</p>
    <h3>Куки</h3>
    <p>При посещении страниц проекта, сайты устанавливают на компьютере посетителя временный идентификатор сессии — т.н. «куки» (cookie). При авторизации могут устанавливаться дополнительные куки, позволяющие избежать повторного ввода имени пользователя (и, возможно, пароля) при следующем визите. Если в браузере пользователя включена возможность сохранения имени пользователя и/или пароля, эта информация будет хранятся до 30-и дней и отправляться на сервер при каждом посещении этого проекта. Использующие общедоступный компьютер и не желающие открывать имя своей учётной записи его последующим пользователям, должны стереть эти куки после использования (в таком случае нужно также очистить кэш браузера).</p>
    <h3>История редактирования страницы</h3>
    <p>История правок или иной вклад в статьях сохраняются навсегда. Модераторы, имеющие более высокий уровень доступа, могут видеть то, что удалено из зоны общедоступного просмотра. В некоторых случаях информация может быть полностью удалена лицами, имеющими доступ к серверам HELPERIA, но за исключением тех редких обстоятельств, когда HELPERIA обязан удалить историю редактирования материалов во исполнение постановления суда или аналогичного юридического процесса, не существует каких-либо гарантий полного удаления подобной информации.</p>
    <h3>Просмотр содержимого страниц</h3>
    <p>При обычном просмотре страниц участниками или другими посетителями, о них собирается не больше информации, чем собирается в протоколах работы среднестатистического сервера в Интернете. Помимо вышеуказанных данных серверных протоколов, собранных в рамках общих технических задач, посетители страниц не подвергаются риску публичной идентификации. Данных серверных протоколов могут включать в себя IP-адрес любого пользователя, но эти сведения никогда не воспроизводится публично.</p>
    <h3>Редактирование страниц</h3>
    <p>Правки страниц проекта идентифицируются с использованием учётной записи редактора.</p>
    <h3>Зарегистрированные участники</h3>
    <p>Зарегистрированные участники не раскрывают публично свои IP-адреса, за исключением случаев злоупотреблений, включая вандализм страниц со стороны этого участника или другого участника с тем же IP-адресом. IP-адреса участников хранятся на серверах ограниченное время, в течение которого они доступны для просмотра администраторами серверов.</p>
    <p>Электронный адрес, указанный в персональных настройках участника, может быть использован HELPERIA для связи с ним. Участники, не указавшие действующий электронный адрес, не могут восстановить пароль в случае его утери. Однако, в этой ситуации участник может связаться с одним из администраторов серверов HELPERIA для указания нового электронного адреса. Участник в любой момент может удалить электронный адрес из настроек учётной записи, чтобы сделать невозможным его использование. Частная переписка участников может быть этими участниками по их желанию, и она не является объектом применения политики HELPERIA.</p>

    <h2>Доступ к персонифицируемой информации и условия её раскрытия</h2>
    <h3>Доступ</h3>
    <p>Деятельность HELPERIA осуществляется в основном силами добровольных редакторов.</p>
    <h3>Раскрытие — Правила раскрытия данных</h3>
    <p>Официальная политика HELPERIA заключается в том, что персонифицируемые данные, доступные из протоколов работы сервера, записей в базе данных, а также через иные необщедоступные методы, могут разглашаться модераторами и сотрудниками HELPERIA в одном из следующих случаев:</p>
    <ol>
      <li>для выполнения вердиктов суда либо других обязательных к исполнению требований правоохранительных органов</li>
      <li>при наличии разрешения затрагиваемого пользователя</li>
      <li>если это необходимо для расследования жалоб о нарушениях</li>
      <li>если информация относится к просмотру страниц сетевыми «пауками» или ботами, и её разглашение требуется для демонстрации или устранения технических проблем</li>
      <li>если пользователь замечался в систематическом вандализме или деструктивных действиях, то персонифицируемые данные могут передаваться провайдеру услуг, канала связи, или иным сторонним лицам или организациям для уточнения блокируемых IP-диапазонов или жалобы провайдеру данного пользователя</li>
      <li>когда существует разумная необходимость защиты прав, собственности и безопасности HELPERIA, участников его проектов или аудитории этих проектов</li>
    </ol>
    <p>За исключением вышеупомянутых случаев, правила HELPERIA не допускают распространение личной информации ни при каких обстоятельствах.</p>
    <h3>Доступ третьих лиц и уведомление зарегистрированных участников при получении судебных постановлений</h3>
    <p>В качестве общего принципа, доступ к персональным данным и их сохранение во всех проектах должны быть минимальными и использоваться только внутренних целей обслуживания проекта. Однако, иногда HELPERIA может получать судебные повестки или другие требования от правоохранительных или судебных органов или эквивалентных им правительственных организаций, содержащие просьбы о раскрытии информации о зарегистрированных участниках, и может поступать в соответствии с этими запросами согласно требованиям законодательства. В случае получения такого требования, HELPERIA в течение трёх рабочих дней после прибытия такой повестки будет пытаться уведомить участников, в отношении которых сделан запрос, направив уведомление по электронной почте на указанные в персональных настройках этих участников адреса электронной почты (если они указаны).</p>
    <p>HELPERIA не может консультировать получившего такое извещение участника по вопросам законодательства или давать советы о том, как реагировать на повестку HELPERIA, однако, отмечает, что означенные участники могут иметь законное право на сопротивление или ограничение использования этой информации в рамках судопроизводства путем подачи ходатайства об отмене такого постановления. Участникам, которые хотят опротестовать повестку или иное подобное требование, следует обращаться за юридической консультацией относительно доступных им в данном случае прав и процедур.</p>
    <p>Если в результате действий участника или его адвоката HELPERIA получает заверенное в судебном органе ходатайство об отмене или ином ограничения такой повестки, HELPERIA не будет раскрывать запрашиваемую информацию до тех пор, пока не получит судебное постановление на этот счёт.</p>
    <p>Зарегистрированный участник, не указавший адрес электронной почты, или удаливший свой адрес электронной почты, либо предоставивший недостоверный адрес, при получении HELPERIA запросов от правоохранительных органов с требованием раскрытия конфиденциальной информации об этом участнике HELPERIA не сможет уведомить его об этом частным образом.</p>
    <h2>Отказ от ответственности</h2>
    <p>HELPERIA верит в то, что поддержание и сохранение конфиденциальности пользовательских данных является важнейшей задачей. Эта политика конфиденциальности, наряду с другими направлениями политики, резолюциями, а также действиями со стороны HELPERIA представляет собой обязательное условие обеспечения безопасности той немногой информации о пользователях, которую мы собираем и сохраняем на наших серверах. Тем не менее, HELPERIA не может гарантировать, что пользовательская информация останется приватной. Мы признаем, что, несмотря на наши усилия, направленные на защиту частной пользовательской информации, стремящиеся к этому лица могут по-прежнему использовать различные методы получения доступа, сбора и раскрытия такой информации. В связи с этим, HELPERIA не может гарантировать отсутствие несанкционированного доступа к информации, предоставленной в ходе участия в проекте.</p>
    <p>В любом случае пользователь, являющийся субъектом персональных данных, принимая настоящую политику конфиденциальности, принимает решение о предоставлении своих персональных данных и дает согласие на их обработку своей волей и в своем интересе. HELPERIA не собирает и не сохраняет персональных данных. При регистрации требуется указать только логин, пароль и адрес электронной почты, в связи с чем HELPERIA вправе осуществлять обработку данных без уведомления уполномоченного органа по защите прав субъектов персональных данных. Любые персональные данные, размещенные пользователем в своём личном кабинете после осуществления регистрации, не обрабатываются HELPERIA, размещаются и раскрываются пользователем на свое личное усмотрение, страх и риск, и HELPERIA не является оператором таких данных в смысле ФЗ РФ №152-ФЗ «О персональных данных» от 27 июля 2006 года.</p>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
}
</script>

<style lang="less" scoped>

</style>
